import { useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import axios from '../../helpers/axios-instance';
import styles from './Login.module.less';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = (props) => {
  const navigate = useNavigate();
  const sendGoogleToken = async (tokenId) => {
    try {
      await axios.post(
        `/v1/users/googleLogin`,
        {
          idToken: tokenId,
        },
        {
          withCredentials: true,
        }
      );
      //again check subscription
      try {
        await axios.get(`/v1/users/checkAuth`, {
          withCredentials: true,
        });
        toast.success("Login Successful");
        navigate('/curriculum');
      } catch (error) {
        if (error.response) toast(error.response.data.message);
      }

    } catch (error) {
      if (error.response) toast.error("Please login in using GGI registered email");
      // console.log('GOOGLE SIGNIN ERROR', error.response);
    }
  };

  useEffect(() => {
    
    const checkAuth = async () => {
      try {
        await axios.get(`/v1/users/checkAuth`, {
          withCredentials: true,
        });
        navigate('/curriculum');
      } catch (error) {
        // if (error.response) toast(error.response.data.message);
      }
    };
    checkAuth();
  }, []);

  const responseGoogle = (response) => {
    sendGoogleToken(response.tokenId);
  };

  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT;
  return (
    <div className={styles.wrapper}>
      <div className={`container ${styles.login}`}>
        <h1 className={styles.login__title}>Log In</h1>
        <GoogleLogin
          clientId={googleClientId}
          buttonText='Login with Google'
          onSuccess={responseGoogle}
          // onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
          className={styles.login__button}
        />
        <ToastContainer />
      </div>
    </div>
  );
};

export default Login;
